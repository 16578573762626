export default {
    create_csvString_from2dArray: function(obj){
        return new Promise((resolve, reject)=>{
            // SET: variables from obj
            let table = obj.table;
            // CREATE: start of csvString
            let csvString = 'data:text/csv;charset=utf-8,'; // start string with csv file header declarations
            // HANDLE: no data
            if( table === null || table === undefined || !table.length){
                reject('ERROR IN create_csvString_from2dArray(table): table argument expects an array, but found null, undefined, a number, an object, or has no length.')
            };
            // LOOP: table
            for(let r = 0; r < table.length; r++){ // traverse rows of the table
                let row = table[r];

                if( !row.length              // row array has no length
                &&  r === table.length - 1){ // AND End of table traversal
                    resolve(csvString);
                }
                else
                if( !row.length){ // row array has no length
                    // SET: newline onto csvString
                    csvString += '\n';
                }
                else{
                    for(let c = 0; c < row.length; c++){ // traverse cols of the row
                        let col = row[c];

                        // MODIFY: col into properly formated colString
                        let colString = `${col}`; // Ensure working with a string
                            colString = colString.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' '); // Clean text to remove multiple spaces and jumpline (which breaks csv)
                            // colString = colString.replace(/"/g, '""'); // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                        // MODIFY: colString into uriEncodedString
                        let uriEncodedString = encodeURI(colString);                    // native URI encoding method
                            uriEncodedString = uriEncodedString.replaceAll('#', '%23'); // hashmarks replaced (breaks csv)
                            uriEncodedString = uriEncodedString.replaceAll("'", '%27'); // single quotes replaced (breaks csv)
                            uriEncodedString = uriEncodedString.replaceAll("_", '%5f'); // underscores replaced (breaks csv)
                            uriEncodedString = uriEncodedString.replaceAll(",", '');    // commas replaced (breaks csv, as it's column separator symbol)

                        // CREATE: colStringWithSeparator
                        let colSeparator = ',';
                        let colStringWithSeparator = `${colSeparator}${uriEncodedString}`

                        // SET: colStringWithSeparator onto csvString
                        csvString += colStringWithSeparator;

                        if( r === table.length - 1 // End of table traversal
                        &&  c === row.length - 1){ // AND End of row traversal
                            resolve(csvString);
                        }
                        else
                        if( c === row.length - 1){ // End of row traversal
                            // SET: newline onto csvString
                            csvString += '\n';
                        };
                    };
                };
            };
        });
    },
    event_downloadCSVFile_fromCSVString: function(obj){
        // SET: variables from object
        let csvString = obj.csvString;
        let title     = obj.title;
        // CREATE: Date Tag
        let date        = new Date(Date.now());
        let yearNumber  = date.getFullYear();
        let monthNumber = date.getMonth()+1;
        let dayNumber   = date.getDate();
        let dateTag     = `${yearNumber}-${monthNumber}-${dayNumber}`;
        // CREATE: link HTML
        let link = `<a id='csvLink' href='${obj.csvString}' download='${title}_${dateTag}.csv'>`;
        // GIVE: link HTML to body
        document.body.insertAdjacentHTML('afterend', link);
        // GET: link on body
        let csvLink = document.querySelector('#csvLink');
        // CLICK: link to download
        csvLink.click();
        // REMOVE: link element
        csvLink.remove();
    }
}
