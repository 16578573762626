<template>
<div class="fill-height">
    <div v-if="ready" class="wrapper fill-height">
        <br>
        <h1>Data Dump</h1>
        <br><hr><br>

        <h2>Sample Requests</h2>
        <v-row justify="space-around">
            <template v-for="metric in metrics">
                <Metric :data="metric"/>
            </template>
        </v-row>
        <v-row justify="end">
            <DownloadJsonToExcel :data="sample_requests" name="sample-requests-dump.xls" :fields="dump_headers" type="csv">
                <v-btn class="exportButton">
                    <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                    Export
                </v-btn>
            </DownloadJsonToExcel>
        </v-row>
        <br><hr><br>

        <h2>Recent Activity (last 300 records)</h2>
        <v-row justify="end">
            <v-btn class='exportButton' @click='event_exportCSV_recentActivity'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </v-row>
        <br><hr><br>

        <v-row>
            <v-col class="pl-0"><h2>Survey Results</h2></v-col>
            <v-col cols="2" class="d-flex flex-column pr-0 py-0">
                <v-btn class='exportButton mb-2' @click="event_exportCSV_surveyResults('users')" title="Export Users">
                    <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                    Export
                </v-btn>
                <v-btn class='exportButton mt-2 mb-2' @click="event_exportCSV_surveyResults('group_users')" title="Export Grouped Users">
                    <v-icon color="white" left>mdi-account-supervisor-outline</v-icon>                    
                    <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                    Export SuperUsers
                </v-btn>
                <v-btn class='exportButton mt-2' @click="event_exportCSV_surveyResults('group_by_speakers')" title="Export results tally by speaker">
                    <v-icon color="white" left>mdi-account-tie-hat-outline</v-icon>                    
                    <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                    Export tally by Speaker
                </v-btn>                
            </v-col>
        </v-row>
        <br><hr><br>
        
        <v-row>
            <v-col class="pl-0"><h2>Events</h2></v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="11" class="d-flex flex-column pr-0 py-0">
                <v-expansion-panels v-model="panelsIndex.events_listing">
                    <v-expansion-panel class="mr-3">
                        <v-expansion-panel-header @click="fetchEventList" v-ripple>
                            <template v-slot:actions>
                                <span class="icon">Listing <v-icon>mdi-chevron-down</v-icon></span>
                            </template>
                            <v-spacer class="header"/>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col v-if="loading.event_list!==true">
                                <v-data-table
                                    id="events-listing"
                                    :headers="eventListing.headers"
                                    :items="eventListing.items"
                                    fixed-header
                                    height="600"
                                    hide-default-footer
                                    disable-pagination
                                />
                                </v-col>
                                <v-col v-else class="d-flex justify-center">
                                <v-card color="primary" dark>
                                    <v-card-text class="text-center">
                                        Loading... Please stand by
                                        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                                    </v-card-text>
                                </v-card>
                                </v-col>                                       
                            </v-row>
                          </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <v-col cols="1" class="d-flex flex-column pr-0 py-0">
                <DownloadJsonToExcel :data="eventListing.items" name="events-listing-dump.xls" :fields="eventListing.dump_headers" type="csv">
                    <v-btn class='exportButton mt-2' title="Export Event List">
                        <v-icon color="white" left>mdi-calendar-outline</v-icon>                    
                        <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                        Export
                    </v-btn>
                </DownloadJsonToExcel> 
            </v-col>
        </v-row>
        <br><hr><br>

        <h2>Certificate Surveys</h2>
        <v-row justify="end">
            <v-btn class='exportButton' @click='event_exportCSV_certificateSurveys'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </v-row>
        <br><hr><br>

        <h2>VIP Members</h2>
        <v-row justify="end">
            <v-btn class='exportButton' @click='event_exportCSV_vipMembers'>
                <v-icon color="white" left>mdi-microsoft-excel</v-icon>
                Export
            </v-btn>
        </v-row>
        <br>

        <v-row no-gutters justify="center" v-if="$store.getters.debug ">
            <template>
                <v-data-table
                  :headers="headers"
                  :items="sample_requests"
                  :items-per-page="50"
                  class="elevation-1"
                ></v-data-table>
            </template>
        </v-row>
    </div>
    <v-dialog v-model="show_loader" hide-overlay persistent width="300">
        <v-card color="primary" dark>
            <v-card-text>
                Loading... Please stand by
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Metric from '@/components/SampleRequest/Metric.vue'
import DownloadJsonToExcel from "vue-json-excel"
import Exporter from '@/mixins/exporter.js'
import _cloneDeep from 'lodash/cloneDeep'

export default {
    name: "DataDumpView",
    components: {
        Metric,
        DownloadJsonToExcel
    },
    created: function(){
        let self = this;
        this.init();
    },
    data: function(){
        return {
            ready: false,
            show_loader: true,
            metrics: [
                {name: "Total Requests", value: 0},
                {name: "Distinct Users", value: 0},
                {name: "Clinics", value: 0}
            ],
            sample_requests: null,
            headers: [
                {
                    text: `Given Name`,
                    align: 'start',
                    sortable: false,
                    value: 'given_name'
                },
                { text: `Family Name`, value: 'family_name' },
                { text: 'Clinic Name', value: 'clinic' },
                { text: `User's e-mail`, value: 'email' },
                { text: 'Province', value: 'prov_state' },
                { text: 'City', value: 'city' },
                { text: 'Address', value: 'address' },
                { text: 'Postal Code', value: 'postal_zip' },
                { text: 'Date of request', value: 'timestamp' },
            ],
            dump_headers: {
                "First Name": "given_name",
                "Last Name": "family_name",
                "Clinic Name": "clinic",
                "User's e-mail": "email",
                "Province": "prov_state",
                "City": "city",
                "Address": "address",
                "Postal Code": "postal_zip",
                "Date of request": "timestamp"
            },
            // Logs data
            logs: null,
            // Questions Data
            aggregateSurveyResultsTableUsers: null,
            aggregateSurveyResultsTableUsersGroupedUsers: null,
            aggregateSurveyResultsTableUsersGroupedBySpeakers: null,
            questionAnswers: null,
            questionInclusionLists: {},
            questionSets: null,
            // Certificates data
            certificates: null,
            // VIP
            VIPRecords: null,
            panelsIndex: {
                events_listing: undefined,
                survey_by_speaker: undefined,
            },
            loading: {
                event_list: false,
            },
            speakers: null,
            events: null,
        }
    },
    methods: {
        async fetchEventList(){

            function getPrograms(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/admin/getPrograms').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }
            function getSpeakers(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/getSpeakers').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }
            
            let self = this
            setTimeout(async ()=> {
                self.loading.event_list = self.panelsIndex.events_listing===undefined ? false : true
                if (self.loading.event_list){
                    let programs = await getPrograms()
                    self.events = programs[0].events
                    self.speakers = await getSpeakers()
                    self.loading.event_list = false
                }
            },0)
        },
        init: async function(){
            let response = await this.sendRequest('get', '/api/samples/list')
            if(response.status==200 && response.data){
                let users = response.data.users
                users = users.map(elem=>{
                    let user = {}
                    user['user_id'] = elem.Username
                    user['email'] = elem.Attributes.filter(attribute => attribute.Name==='email').map(email=>email.Value)[0]
                    user['given_name'] = elem.Attributes.filter(attribute => attribute.Name==='given_name').map(given_name=>given_name.Value)[0]
                    user['family_name'] = elem.Attributes.filter(attribute => attribute.Name==='family_name').map(family_name=>family_name.Value)[0]
                    return user
                })
                this.sample_requests = response.data.samples.map(elem=>{
                    let contact = JSON.parse(elem.contact)
                    contact['user_id'] = elem.user_id
                    contact['timestamp'] = Intl.DateTimeFormat("en-ca", {dateStyle: 'short', timeStyle: 'short', hour12: false }).format(new Date(elem.timestamp))
                    contact['event_id'] = elem.event_id
                    contact['email'] = users.filter(user=>user.user_id===elem.user_id).map(email=>email.email)[0]
                    contact['given_name'] = users.filter(user=>user.user_id===elem.user_id).map(given_name=>given_name.given_name)[0]
                    contact['family_name'] = users.filter(user=>user.user_id===elem.user_id).map(family_name=>family_name.family_name)[0]
                    return contact
                })

                // SET: certificates
                this.pass_certificatesFromServer();

                // SET: logs
                this.pass_logsFromServer();

                // SET: VIPRecords
                this.pass_VIPRecordsFromServer();

                // SET: questionSets and subsequent data
                this.pass_questionSetList_toQuestionSets()
                .then(async()=>{
                    await this.pass_questionSetAnswers_toQuestionAnswers()
                    await this.create_questionInclusionLists()
                    await this.delete_answersWithNoQuestions_fromQuestionAnswers()
                    await this.set_questionSetsData_ontoQuestionAnswersObj('users')
                    await this.set_questionSetsData_ontoQuestionAnswersObj('group_users')
                    await this.create_aggregateSurveyResultsTable('users')
                    await this.create_aggregateSurveyResultsTable('group_users')
                    await this.set_questionSetsData_ontoQuestionAnswersObj('group_by_speakers')
                    await this.create_aggregateSurveyResultsTable('group_by_speakers')

                    this.ready = true;
                });
            }
        },
        create_aggregateSurveyResultsTable: function(user_category){
            let self = this
            return new Promise((resolve, reject)=>{
                let aggregateSurveyResultsTable = [
                    ['Question Set Id', 'Question Set Title', 'Question Id', 'Outer Question Text', 'Group Id', 'Inner Question Text', 'Type', 'Answer', 'Tally']
                ];
                user_category==='group_by_speakers' && aggregateSurveyResultsTable[0].unshift('Speaker')
                
                let obj_questionAnswers = this.questionAnswers[user_category];
                
                // CATCH, EXIT
                if( obj_questionAnswers == null || obj_questionAnswers == undefined || Object.keys(obj_questionAnswers).length == 0){
                    resolve();
                    return;
                }

                let i = 0;
                for(let key_questionSet in obj_questionAnswers){
                    
                    let speaker = null
                    if (user_category==='group_by_speakers'){
                        //speaker = key_questionSet.slice(0, -2);
                        speaker = key_questionSet
                    }
                              
                    let obj_questionSet   = obj_questionAnswers[key_questionSet]
                    let id_questionSet    = Number(key_questionSet.split('_')[1])
                    let title_questionSet = obj_questionSet.title
                    
                    // CATCH, HANDLE, EXIT
                    if( (obj_questionSet == null || obj_questionSet == undefined || Object.keys(obj_questionSet).length == 0) &&  i === Object.keys(obj_questionAnswers).length - 1){
                        resolve();
                        return;
                    }
                    else if( obj_questionSet == null || obj_questionSet == undefined || Object.keys(obj_questionSet).length == 0){
                        i++;
                        continue;
                    }

                    // HANDLE: comma as it breaks csv, as it's the column separator symbol
                    if( title_questionSet !== undefined &&  title_questionSet !== null &&  title_questionSet.includes(',') ){
                        title_questionSet = title_questionSet.replaceAll(",", '');
                    }
                    // HANDLE: potential <br> and following html. It's in there for extra style and info when users read questions, but don't want in output.
                    if( title_questionSet !== undefined &&  title_questionSet !== null &&  title_questionSet.includes('<br>') ){
                        title_questionSet = title_questionSet.split("<br>")[0];
                    }

                    let ii = 0;
                    for(let key_question in obj_questionSet){

                        let obj_question       = obj_questionSet[key_question];
                        let id_question        = Number(key_question.split('_')[1]);
                        let questionText_outer = obj_question.questionText_outer;

                        // CATCH, HANDLE, EXIT
                        if( (obj_question == null || obj_question == undefined || Object.keys(obj_question).length == 0)
                                &&  ii === Object.keys(obj_questionSet).length - 1){
                            resolve();
                            return;
                        }
                        else if( obj_question == null || obj_question == undefined || Object.keys(obj_question).length == 0){
                            ii++;
                            continue;
                        }

                        // HANDLE: potential comma as it breaks csv, as it's the column separator symbol
                        if( questionText_outer !== undefined &&  questionText_outer !== null &&  questionText_outer.includes(',') ){
                            questionText_outer = questionText_outer.replaceAll(",", '');
                        }
                        // HANDLE: potential <br> and following html. It's in there for extra style and info when users read questions, but don't want in output.
                        if( questionText_outer !== undefined &&  questionText_outer !== null &&  questionText_outer.includes('<br>') ){
                            questionText_outer = questionText_outer.split("<br>")[0];
                        }

                        let iii = 0;
                        for(let key_group in obj_question){

                            let obj_group          = obj_question[key_group];
                            let id_group           = Number(key_group.split('_')[1]);
                            let questionText_inner = obj_group.questionText_inner;
                            let questionType       = obj_group.questionType;

                            // CATCH, HANDLE, EXIT
                            if( (obj_group == null || obj_group == undefined || Object.keys(obj_group).length == 0) &&  iii === Object.keys(obj_question).length - 1){
                                resolve();
                                return;
                            }
                            else if( obj_group == null || obj_group == undefined || Object.keys(obj_group).length == 0){
                                iii++;
                                continue;
                            }

                            // HANDLE: comma as it breaks csv, as it's the column separator symbol
                            if( questionText_inner !== undefined &&  questionText_inner !== null &&  questionText_inner.includes(',') ){
                                questionText_inner = questionText_inner.replaceAll(",", '');
                            }
                            // HANDLE: potential <br> and following html. It's in there for extra style and info when users read questions, but don't want in output.
                            if( questionText_inner !== undefined &&  questionText_inner !== null &&  questionText_inner.includes('<br>') ){
                                questionText_inner = questionText_inner.split("<br>")[0];
                            }

                            delete obj_group.questionText_inner;
                            delete obj_group.questionType;

                            if( isNaN(id_group) === false){
                                let iv = 0;
                                for(let key_choice in obj_group){

                                    let selection = `${key_choice}`;
                                    let tally     = `${obj_group[key_choice]}`;

                                    // HANDLE: comma. If comma found in field, then need to surround field with double-quotes. (note: don't replace single quote with double quote in CSVString creating function).
                                    if( selection !== undefined &&  selection !== null &&  selection.includes(',') ){
                                        selection = `"${selection}"`;
                                    }
                                    if( tally !== undefined &&  tally !== null &&  tally.includes(',') ){
                                        tally = `"${tally}"`;
                                    }

                                    let row = [id_questionSet, title_questionSet, id_question, questionText_outer, id_group, questionText_inner, questionType, selection, tally]
                                    user_category==='group_by_speakers' && row.unshift(speaker)
                                    //console.log(row)
                                    aggregateSurveyResultsTable.push(row);

                                    iv++;
                                    if( i === Object.keys(obj_questionAnswers).length - 1 &&  ii === Object.keys(obj_questionSet).length - 1
                                            &&  iii === Object.keys(obj_question).length - 1 &&  iv === Object.keys(obj_group).length){ //end of loop
                                        //resolve();
                                        //return;
                                    }
                                }
                            }

                            iii++;
                            if( i === Object.keys(obj_questionAnswers).length - 1 &&  ii === Object.keys(obj_questionSet).length - 1 &&  iii === Object.keys(obj_question).length){ //end of loop
                                //resolve();
                                //return;
                            }
                        }

                        ii++;
                        if( i === Object.keys(obj_questionAnswers).length - 1 &&  ii === Object.keys(obj_questionSet).length){ //end of loop
                            //resolve();
                            //return;
                        }
                    }

                    i++;
                    if( i === Object.keys(obj_questionAnswers).length){ //end of loop
                        //resolve();
                        //return;
                    }
                }
                if (user_category==='users') {
                    self.aggregateSurveyResultsTableUsers = aggregateSurveyResultsTable
                }
                else if (user_category==='group_users') {
                    self.aggregateSurveyResultsTableUsersGroupedUsers = aggregateSurveyResultsTable
                }
                else if (user_category==='group_by_speakers') {
                    self.aggregateSurveyResultsTableUsersGroupedBySpeakers = aggregateSurveyResultsTable
                }                
                
                resolve();
                return;                
            });
        },
        create_certificateSurveyTable_fromCertificatesData: function(){
            return new Promise((resolve, reject)=>{
                // CREATE: table array
                let table = [];
                // HANDLE: return on empty data
                if( this.certificates.length === 0){
                    resolve(table);
                    return;
                };
                // CREATE: table from certificates data
                for(let i = 0; i < this.certificates.length; i++){
                    let obj = this.certificates[i];
                    let answers = JSON.parse(obj.answers);
                    // CREATE: row
                    let row = [
                        obj.givenName,
                        obj.familyName,
                        obj.language,
                        obj.email,
                        obj.clinic,
                        obj.country,
                        obj.prov_state,
                        obj.city,
                        obj.address,
                        obj.postal_zip,
                        obj.profession,
                        answers['1'],
                        answers['2'],
                        answers['3'],
                        Intl.DateTimeFormat("en-ca", {dateStyle: 'short', timeStyle: 'short', hour12: false }).format(new Date(obj.timestamp)),
                    ];
                    // SET: row into table
                    table.push(row)
                    if( i === this.certificates.length - 1){ // end of loop
                        resolve(table);
                    };
                };
            });
        },
        create_logsTable_fromLogsData: function(){
            return new Promise((resolve, reject)=>{
                // CREATE: start table
                let table = [];
                // HANDLE: return on empty data
                if( this.logs.length === 0){
                    resolve(table);
                    return;
                };
                // CREATE: table from logs data
                for(let i = 0; i < this.logs.length; i++){
                    let obj = this.logs[i];
                    // MODIFY: title
                    if( obj.title !== null){
                        obj.title = JSON.parse(obj.title);
                        obj.title = obj.title['en-ca'];
                    };
                    // CREATE: row
                    let row = [
                        obj.firstName,
                        obj.lastName,
                        obj.language,
                        obj.email,
                        obj.clinic,
                        obj.country,
                        obj.prov_state,
                        obj.city,
                        obj.address,
                        obj.postal_zip,
                        obj.profession,
                        obj.action,
                        obj.title,
                        Intl.DateTimeFormat("en-ca", {dateStyle: 'short', timeStyle: 'short', hour12: false }).format(new Date(obj.timestamp)),
                    ];
                    //obj.action==='submit_survey' && obj.email==='maneja@ctccomm.com' && console.log(obj.timestamp)

                    // SET: row into table
                    table.push(row);
                    if( i === this.logs.length - 1){ // end of loop
                        resolve(table);
                    };
                };
            });
        },
        create_questionInclusionLists: function(){
            return new Promise(async(resolve, reject)=>{
                let obj_questionInclusionLists = this.questionInclusionLists;
                let array_questionSets = this.questionSets;

                // CHECK, EXIT
                if( array_questionSets == null || array_questionSets == undefined || array_questionSets.length == 0){
                    console.log('ERROR: no array_questionSets items to work on.', obj_questionInclusionLists);
                    resolve();
                    return;
                };

                for(let i = 0; i < array_questionSets.length; i++){
                    let obj_questionSet = array_questionSets[i];
                    let id_questionSet = obj_questionSet.id;
                    let array_questions = obj_questionSet.questions;

                    // CHECK, EXIT: last iteration of questionSets && no questions found
                    if( array_questions.length == 0
                    &&  i === array_questionSets.length - 1 ){
                        resolve();
                        return;
                    };

                    // Create object for questionSetId if not there
                    if( obj_questionInclusionLists.hasOwnProperty(id_questionSet) === false){
                        obj_questionInclusionLists[id_questionSet] = [];
                    };

                    for(let ii = 0; ii < array_questions.length; ii++){
                        let obj_question = array_questions[ii];
                        let id_question = obj_question.id;

                        obj_questionInclusionLists[id_questionSet].push(id_question);

                        if( i === array_questionSets.length - 1
                        &&  ii === array_questions.length - 1){ // end of loop
                            resolve();
                        };
                    };
                };
            });
        },
        create_vipMembersTable_fromVipRecords: function(){
            return new Promise((resolve, reject)=>{
                // CREATE: start table
                let table = [];
                // HANDLE: return on empty data
                if( this.VIPRecords.length === 0){
                    resolve(table);
                    return;
                };
                // CREATE: table from data
                for(let i = 0; i < this.VIPRecords.length; i++){
                    let obj = this.VIPRecords[i];
                    // CREATE: row
                    let row = [
                        obj.givenName,
                        obj.familyName,
                        obj.language,
                        obj.email,
                        obj.clinic ? obj.clinic : "-",
                        obj.country ? obj.country : "-",
                        obj.prov_state ? obj.prov_state : "-",
                        obj.city ? obj.city : "-",
                        obj.address ? obj.address : "-",
                        obj.postal_zip ? obj.postal_zip : "-",
                        obj.profession ? obj.profession : "-",
                        obj.timestamp ? Intl.DateTimeFormat("en-ca", {dateStyle: 'short', timeStyle: 'short', hour12: false }).format(new Date(obj.timestamp)) : "-"
                    ];
                    // SET: row into table
                    table.push(row);
                    if( i === this.VIPRecords.length - 1){ // end of loop
                        resolve(table);
                    };
                };
            });
        },
        delete_answersWithNoQuestions_fromQuestionAnswers: function(){
            return new Promise((resolve, reject)=>{
                for (let users_set in this.questionAnswers){
                    let obj_questionAnswers = this.questionAnswers[users_set]
                    if( obj_questionAnswers == null || obj_questionAnswers == undefined || Object.keys(obj_questionAnswers).length == 0){
                        console.log('Error: no workable questionAnswers object; either null, undefined, or no properties within.');
                        resolve();
                        return;
                    }

                    let i = 0;
                    for(let key_questionSet in obj_questionAnswers){

                        let obj_questionSet = obj_questionAnswers[key_questionSet];
                        let id_questionSet = key_questionSet.split('_')[1];
                        let array_inclusion = this.questionInclusionLists[id_questionSet];

                        if( obj_questionSet == null || obj_questionSet == undefined || Object.keys(obj_questionSet).length == 0){
                            console.log('Error: no workable questionSet object; either null, undefined, or no properties within.');
                            resolve();
                            return;
                        }

                        let ii = 0;
                        for(let key_question in obj_questionSet){

                            let key_id = Number(key_question.split('_')[1]);

                            if( array_inclusion.includes(key_id) === false){
                                delete obj_questionAnswers[key_questionSet][key_question];
                            }

                            ii++;
                            // END OF BOTH LOOPS
                            if( i === Object.keys(obj_questionAnswers).length - 1 &&  ii === Object.keys(obj_questionSet).length){
                                resolve();
                            }
                        }

                        i++
                    }
                }
            })
        },
        event_exportCSV_certificateSurveys: async function(){
            // READ: questions from data store labels
            let q1 = this.$store.getters.labels.certificate.question_1._text['en-ca'];
            let q2 = this.$store.getters.labels.certificate.question_2._text['en-ca'];
            let q3 = this.$store.getters.labels.certificate.question_3._text['en-ca'];
            // CREATE: start of table
            let table = [
                ['Given Name', 'Family Name', 'Preferred Language', 'Email', 'Clinic', 'Country', 'Province/State', 'City', 'Address', 'Postal/Zip', 'Profession', q1, q2, q3, 'Timestamp',]
            ];
            // CREATE: rest of table from certificates
            let restOfTable = await this.create_certificateSurveyTable_fromCertificatesData();
            table = table.concat(restOfTable);
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'CertificateSurvey'});
        },
        event_exportCSV_recentActivity: async function(){
            // CREATE: start of table
            let table = [
                ['Given Name', 'Family Name', 'Preferred Language', 'Email', 'Clinic Name', 'Country', 'Province/State', 'City', 'Address', 'Postal/Zip Code', 'Profession', 'Action', 'Event Name', 'Timestamp']
            ];
            // CREATE: rest of table from logs
            let restOfTable = await this.create_logsTable_fromLogsData();
            table = table.concat(restOfTable);
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'RecentActivity'});
        },
        event_exportCSV_surveyResults: async function(user_category){
            let table
            if (user_category==='users'){
                table = this.aggregateSurveyResultsTableUsers
            }
            else if (user_category==='group_users'){
                table = this.aggregateSurveyResultsTableUsersGroupedUsers
            }
            else if (user_category==='group_by_speakers'){
                table = this.aggregateSurveyResultsTableUsersGroupedBySpeakers
            }

            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            let file_name = user_category==='group_by_speakers' ? 'EvaluationSurvey_by_Speakers' : 'EvaluationSurveyResults'
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: file_name});
        },
        event_exportCSV_vipMembers: async function(){
            // CREATE: start of table
            let table = [
                ['Given Name', 'Family Name', 'Preferred Language', 'Email', 'Clinic Name', 'Country', 'Province/State', 'City', 'Address', 'Postal/Zip Code', 'Profession', 'Time of VIP opt-in']
            ];
            // CREATE: rest of table
            let restOfTable = await this.create_vipMembersTable_fromVipRecords();
            table = table.concat(restOfTable);
            // CREATE: CSV string
            let csvString = await Exporter.create_csvString_from2dArray({table: table});
            // EVENT: download CSV file
            Exporter.event_downloadCSVFile_fromCSVString({csvString: csvString, title: 'VIPMembers'});
        },
        pass_certificatesFromServer: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = '/api/certificate/getCertificates';
                self.sendRequest('GET', url).then((response)=>{
                    // console.log('DATA back from pass_certificatesFromServer', JSON.parse(JSON.stringify(response.data)));
                    this.certificates = response.data
                    resolve()
                })
            })
        },
        pass_logsFromServer: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = '/api/dashboardMetrics';
                // CREATE: date_end string
                let date        = new Date(Date.now());
                let yearNumber  = date.getFullYear();
                let monthNumber = date.getMonth()+1;
                if( monthNumber.toString().length === 1){
                    monthNumber = `0${monthNumber}`;
                };
                let dayNumber   = date.getDate();
                if( dayNumber.toString().length === 1){
                    dayNumber   = `0${dayNumber}`;
                };
                let date_end    = `${yearNumber}-${monthNumber}-${dayNumber}`;
                // CREATE: sendObj
                let sendObj = {
                    date_start: '2022-01-01',
                    date_end:   date_end
                };
                self.sendRequest('POST', url, sendObj).then((response)=>{
                    // console.log(response.data, 'DATA OBJ BACK FROM pass_dashboardMetrics_toMetrics');
                    this.logs = response.data.logs;
                    resolve();
                });
            });
        },
        pass_questionSetAnswers_toQuestionAnswers: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = `/api/dashboardMetrics/1`;
                self.sendRequest('GET', url).then((response)=>{
                    // console.log(JSON.parse(JSON.stringify(response.data)), 'DATA back from pass_questionSetAnswers_toQuestionAnswers');
                    this.questionAnswers = response.data;
                    resolve();
                });
            });
        },
        pass_questionSetList_toQuestionSets: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = `/api/questionSet/admin/list`;
                self.sendRequest('GET', url).then((response)=>{
                    // console.log(JSON.parse(JSON.stringify(response.data)), 'DATA back from pass_questionSetList');
                    this.questionSets = JSON.parse(JSON.stringify(response.data));
                    resolve();
                });
            });
        },
        pass_VIPRecordsFromServer: async function(){
            return new Promise((resolve, reject)=>{
                let self = this;
                let url = '/api/dashboardMetrics';
                let sendObj = {
                    getVIP: true,
                    userId: 'all'
                };
                self.sendRequest('POST', url, sendObj).then((response)=>{
                    this.VIPRecords = response.data;
                    resolve();
                });
            });
        },
        processSingleSet: async function(dataSet){
            let questionSets = this.questionSets

            return new Promise((resolve, reject)=>{                
                // LOOP
                for(let i = 0; i < questionSets.length; i++){

                    // VARRIABLES
                    let obj_questionSet   = questionSets[i]
                    let id_questionSet    = obj_questionSet.id
                    let title_questionSet = obj_questionSet.title['en-ca']
                    let array_questions   = obj_questionSet.questions
                    
                    // CATCH, EXIT OR SKIP
                    if( dataSet[`questionSet_${id_questionSet}`] == undefined &&  i === questionSets.length - 1){
                        resolve()
                        return
                    }
                    else if( dataSet[`questionSet_${id_questionSet}`] == undefined){
                        continue
                    }

                    // SET
                    dataSet[`questionSet_${id_questionSet}`].title = title_questionSet;

                    // LOOP
                    for(let ii = 0; ii < array_questions.length; ii++){

                        // VARIABLES
                        let obj_question       = array_questions[ii]
                        let id_question        = obj_question.id
                        let questionText_outer = obj_question.body.text['en-ca']
                        let array_groups       = obj_question.groups

                        // CATCH, EXIT OR SKIP
                        if( dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`] == undefined &&  ii === array_questions.length - 1){
                            resolve()
                            return
                        }
                        else if( dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`] == undefined){
                            continue
                        }

                        // SET & Strip out HTML tags
                        let html = questionText_outer
                        let div = document.createElement("div")
                        div.innerHTML = html
                        dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`].questionText_outer = div.textContent || div.innerText || ""

                        // LOOP
                        for(let iii = 0; iii < array_groups.length; iii++){

                            // VARIABLES
                            let obj_group          = array_groups[iii]
                            let id_group           = obj_group.id
                            let questionText_inner = obj_group.text['en-ca']
                            let questionType       = obj_group.type
                            let choices = obj_group.choices
                            
                            // CATCH, EXIT OR SKIP
                            if( dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`]===undefined &&  iii===array_groups.length - 1){
                                resolve()
                                return
                            }
                            else if( dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`]=== undefined){
                                continue
                            }

                            // SET
                            dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`].questionText_inner = questionText_inner
                            dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`].questionType = questionType
                            
                           
                            let temp
                            if (questionType=='checkbox' && (`question_${id_question}`==='question_5' || `question_${id_question}`==='question_6')){
                                for(let ch=0; ch<choices.length; ch++){
                                    if (dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]!==undefined){
                                        let answerValue = dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]
                                        let textKey = choices[ch].text['en-ca']
                                        // Strip out HTML tags of textKey if presented
                                        let divTextKey = document.createElement("div")
                                        divTextKey.innerHTML = textKey                      
                                        let stripedTextKey = divTextKey.textContent || divTextKey.innerText || ""                                        
                                        dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][stripedTextKey] = answerValue
                                        delete dataSet[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]                                      
                                    }
                                }                                
                            }

                            // POSSIBLE END OF LOOP
                            if( i === questionSets.length - 1 &&  ii === array_questions.length - 1 &&  iii === array_groups.length - 1){ // end of loop
                                resolve()
                                return
                            }
                        }

                        // POSSIBLE END OF LOOP
                        if( i === questionSets.length - 1 &&  ii === array_questions.length - 1){ // end of loop
                            resolve()
                            return
                        }
                    }

                    // POSSIBLE END OF LOOP
                    if( i === questionSets.length - 1){ // end of loop
                        resolve()
                        return
                    }
                }                
                resolve()
            })
        },
        set_questionSetsData_ontoQuestionAnswersObj: async function(user_category){
            let questionSets = this.questionSets;
            let self = this
            return new Promise( async (resolve, reject)=>{
                if (user_category==="group_by_speakers"){                    
                    let obj_questionAnswers = _cloneDeep(self.questionAnswers[user_category])
                    let processedObject = {}
                    for(let key_questionSet in obj_questionAnswers){
                        // transform Speaker_QuestionSetId to question set id expected notation
                        let questionSetId = key_questionSet.substring(key_questionSet.indexOf("_") + 1)
                        let newKey = `questionSet_${questionSetId}`                        

                        processedObject[newKey] = _cloneDeep(obj_questionAnswers[key_questionSet])
                        await self.processSingleSet(processedObject)
                        obj_questionAnswers[key_questionSet] = processedObject[newKey]
                    }
                    self.questionAnswers[user_category] = obj_questionAnswers
                    resolve()
                    return
                }
                else {
                    let obj_questionAnswers = self.questionAnswers[user_category]
                    // LOOP
                    for(let i = 0; i < questionSets.length; i++){

                        // VARRIABLES
                        let obj_questionSet   = questionSets[i];
                        let id_questionSet    = obj_questionSet.id;
                        let title_questionSet = obj_questionSet.title['en-ca'];
                        let array_questions   = obj_questionSet.questions;

                        // CATCH, EXIT OR SKIP
                        if( obj_questionAnswers[`questionSet_${id_questionSet}`] == undefined &&  i === questionSets.length - 1){
                            resolve()
                            return
                        }
                        else if( obj_questionAnswers[`questionSet_${id_questionSet}`] == undefined){
                            continue
                        }

                        // SET
                        obj_questionAnswers[`questionSet_${id_questionSet}`].title = title_questionSet;

                        // LOOP
                        for(let ii = 0; ii < array_questions.length; ii++){

                            // VARIABLES
                            let obj_question       = array_questions[ii];
                            let id_question        = obj_question.id;
                            let questionText_outer = obj_question.body.text['en-ca'];
                            let array_groups       = obj_question.groups;

                            // CATCH, EXIT OR SKIP
                            if( obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`] == undefined &&  ii === array_questions.length - 1){
                                resolve()
                                return
                            }
                            else if( obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`] == undefined){
                                continue
                            }

                            // SET & Strip out HTML tags
                            let html = questionText_outer
                            let div = document.createElement("div")
                            div.innerHTML = html
                            obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`].questionText_outer = div.textContent || div.innerText || ""

                            // LOOP
                            for(let iii = 0; iii < array_groups.length; iii++){

                                // VARIABLES
                                let obj_group          = array_groups[iii];
                                let id_group           = obj_group.id;
                                let questionText_inner = obj_group.text['en-ca'];
                                let questionType       = obj_group.type;
                                let choices = obj_group.choices

                                // CATCH, EXIT OR SKIP
                                if( obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`]===undefined &&  iii===array_groups.length - 1){
                                    resolve()
                                    return
                                }
                                else if( obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`]=== undefined){
                                    continue
                                }

                                // SET
                                obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`].questionText_inner = questionText_inner
                                obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`].questionType = questionType


                                let temp
                                if (questionType=='checkbox' && (`question_${id_question}`==='question_5' || `question_${id_question}`==='question_6')){
                                    for(let ch=0; ch<choices.length; ch++){
                                        if (obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]!==undefined){
                                            let answerValue = obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]
                                            let textKey = choices[ch].text['en-ca']
                                            // Strip out HTML tags of textKey if presented
                                            let divTextKey = document.createElement("div")
                                            divTextKey.innerHTML = textKey                      
                                            let stripedTextKey = divTextKey.textContent || divTextKey.innerText || ""                                        
                                            obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][stripedTextKey] = answerValue
                                            delete obj_questionAnswers[`questionSet_${id_questionSet}`][`question_${id_question}`][`group_${id_group}`][choices[ch].value]                                      
                                        }
                                    }                                
                                }

                                // POSSIBLE END OF LOOP
                                if( i === questionSets.length - 1 &&  ii === array_questions.length - 1 &&  iii === array_groups.length - 1){ // end of loop
                                    resolve()
                                    return
                                }
                            }

                            // POSSIBLE END OF LOOP
                            if( i === questionSets.length - 1 &&  ii === array_questions.length - 1){ // end of loop
                                resolve()
                                return
                            }
                        }

                        // POSSIBLE END OF LOOP
                        if( i === questionSets.length - 1){ // end of loop
                            resolve()
                            return
                        }
                    }
                }
            })
        }
    },
    computed: {
        caclTotalRequests: function(){
            return this.ready ? this.sample_requests.length : 0;
        },
        caclDistinctUsers: function(){
            if (this.ready===false){
                return 0
            }
            else {
                let userIDs = this.sample_requests.map(elem=>elem.user_id)
                return [...new Set(userIDs)].length
            }
        },
        calcClinics: function(){
            if (this.ready===false){
                return 0
            }
            else {
                let clinics = this.sample_requests.map(elem=>elem.clinic)
                return [...new Set(clinics)].length
            }
        },
        eventListing() {
            let items = []
            if (this.events && this.speakers){
                for(let i=0; i<this.events.length; i++){
                    let eventSpeakers = this.events[i].speakers
                    let speakers = ''
                    for(let s=0; s<eventSpeakers.length; s++ ){
                        let speakerDetails = this.speakers.filter((item)=>item.id===eventSpeakers[s])
                        speakers += `${speakerDetails[0].given_name} ${speakerDetails[0].family_name};`
                    }
                    let item = {title: this.events[i].title['en-ca'], speakers:speakers, date: Intl.DateTimeFormat("en-ca", {dateStyle: 'short', timeStyle: 'short', hour12: false }).format(new Date(this.events[i].start_time)), publish: this.events[i].publish, canceled: this.events[i].cancelled, language: this.events[i].language }
                    items.push(item)
                }
            }
            return {
                dump_headers: {
                    "Event": "title",
                    "Speakers": "speakers",
                    "Date": "date",
                    "Published": "publish",
                    "Canceled": "canceled",
                    "Language": "language",
                },
                headers: [
                    { text: 'Event', align: 'start', sortable: true, value: 'title'},
                    { text: 'Speakers', sortable: true, value: 'speakers' },
                    { text: 'Date', sortable: true, value: 'date', width: 250 },
                    { text: 'Published', sortable: true, value: 'publish' },
                    { text: 'Canceled', sortable: true, value: 'canceled' },
                    { text: 'Language', sortable: true, value: 'language' },
                ],
                items: items
            }
        }
    },
    watch: {
        ready: function(after, before){
            return this.show_loader = !after
        },
        caclTotalRequests: function(after, before){
           this.metrics[0].value = after
        },
        caclDistinctUsers: function(after, before){
           this.metrics[1].value = after
        },
        calcClinics: function(after, before){
           this.metrics[2].value = after
        }
    }
}
</script>

<style lang="scss" scoped>
.row {
    margin: 0;
}

.wrapper {
    background-color: rgb(245, 246, 248);
    padding: 0 12%;

    .v-btn {
        background-color: $primary_light_1;
        color: white;
    }
}

h1, h2 {
    color: $secondary;
    font-weight: 500;
    margin-bottom: 16px;
}
h1 {
    font-size: 20px;
}
h2 {
    font-size: 16px;
}

.flex_exportButton {
    justify-content: flex-end;
}
.exportButton {
    background-color: $primary_light_1;
    color: white;
    text-transform: none;
}
    .icon {
        order: 0;
    }

    .header {
        order: 1;
    }
</style>
